
import { Vue, Component  } from "vue-property-decorator";
import { EmpreendimentoService, PessoaService, TipoAditamentoContrato, UsuarioService } from "@/core/services/cadastros";
import { FormaPagamentoService, SituacaoReceitaParcelaService, TipoReceitaService } from "@/core/services/financeiro";
import { EmpresaService } from "@/core/services/compras/EmpresaService";

@Component
export default class CfgReceita extends Vue {
    filter: {
      empreendimentoId: any;
      numeroContrato:string;
      formaPagamento:string;
      usuarioBaixa:string; 
      clienteId:string;
      situacaoParcelaId:any;
      contratoId:string;
      tipoReceitaId: any;
      dataVencimentoInicial:string;
      dataVencimentoFinal:string;
      dataBaixaInicial:string;
      dataBaixaFinal:string;
      empresaId:any,
    } = {
      empreendimentoId: 0,
      numeroContrato: "",
      formaPagamento:"",
      usuarioBaixa:"",
      clienteId:"",
      situacaoParcelaId:0,
      contratoId:"",
      tipoReceitaId:0,
      dataVencimentoInicial:"",
      dataVencimentoFinal:"",
      dataBaixaInicial:"",
      dataBaixaFinal: "",
      empresaId: 0,
    };

    
    /* EMPRESAS */
    SelecionarEmpresa(){
      this.$nextTick(() => {
        if (this.selecionarTodosEmpresas) {
          this.filter.empresaId = []
        } else {
          this.filter.empresaId = this.empresas.slice().map((x:any)=>x.id)
        }
      })
    }
    get selecionarTodosEmpresas(){
      return this.filter.empresaId.length === this.empresas.length
    }

    get SelecionarAlgunsEmpresas(){
      return this.filter.empresaId.length > 0 && !this.selecionarTodosEmpresas
    }

    get iconEmpresa(){
      if (this.selecionarTodosEmpresas) return 'mdi-close-box'
      if (this.SelecionarAlgunsEmpresas) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    }

    get textEmpresa(){
      if (this.selecionarTodosEmpresas) return 'Desmarcar'
      if (this.SelecionarAlgunsEmpresas) return 'Selecionando...'
      return 'Selecionar todos'
    } 

    /* SITUAÇÃO DA PARCELA */
    SelecionarSituacao(){ 
       this.$nextTick(() => {
          if (this.selecionarTodosSituacao) {
          this.filter.situacaoParcelaId = []
          } else {
            this.filter.situacaoParcelaId = this.situacoes.slice().map((x:any)=>x.id)
          }
      })
    }

    get selecionarTodosSituacao(){
      return this.filter.situacaoParcelaId.length === this.situacoes.length
    }

    get SelecionarAlgunsSituacao(){
      return this.filter.situacaoParcelaId.length > 0 && !this.selecionarTodosSituacao
    }

    get iconSituacao(){
      if (this.selecionarTodosSituacao) return 'mdi-close-box'
      if (this.SelecionarAlgunsSituacao) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    }

    get textSituacao(){
      if (this.selecionarTodosSituacao) return 'Desmarcar'
      if (this.SelecionarAlgunsSituacao) return 'Selecionando...'
      return 'Selecionar todos'
    }


    /* TIPO DE RECEITA  */
    SelecionarTipoReceita() {
      this.$nextTick(() => {
          if (this.selecionarTodos) {
          this.filter.tipoReceitaId = []
          } else {
            this.filter.tipoReceitaId = this.tiposReceitas.slice().map((x:any)=>x.id)
          }
      })
    }

    get selecionarTodos(){
      return this.filter.tipoReceitaId.length === this.tiposReceitas.length
    }

    get SelecionarAlguns(){
      return this.filter.tipoReceitaId.length > 0 && !this.selecionarTodos
    }

    get icon(){
      if (this.selecionarTodos) return 'mdi-close-box'
      if (this.SelecionarAlguns) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    }

    get text(){
      if (this.selecionarTodos) return 'Desmarcar'
      if (this.SelecionarAlguns) return 'Selecionando...'
      return 'Selecionar todos'
    }


     /* TIPO DE RECEITA  */
    get iconEmpreendimento(){
      if (this.selecionarTodosEmpreendimentos) return 'mdi-close-box'
      if (this.SelecionarAlgunsEmpreendimentos) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    }

    get textEmpreendimento(){
      if (this.selecionarTodosEmpreendimentos) return 'Desmarcar'
      if (this.SelecionarAlgunsEmpreendimentos) return 'Selecionando...'
      return 'Selecionar todos'
    }

    SelecionarEmpreendimento() {
      this.$nextTick(() => {
          if (this.selecionarTodosEmpreendimentos) {
          this.filter.empreendimentoId = []
          } else {
            this.filter.empreendimentoId = this.empreendimentos.slice().map((x:any)=>x.id)
          }
      })
    }
 
    get selecionarTodosEmpreendimentos(){
      return this.filter.empreendimentoId.length === this.empreendimentos.length
    }
    get SelecionarAlgunsEmpreendimentos(){
      return this.filter.empreendimentoId.length > 0 && !this.selecionarTodosEmpreendimentos
    }

    pagamentoAutomatico= [
      {id:1, nome:"Sim", condicao:true},
      {id:2, nome:"Não", condicao:false},
    ]

    valid = true;

    pessoas:any = [];
    formaPagamento:any = [];
    empreendimentos: any = [];
    listaUsuarios:any = []; 
    situacoes:any = [];
    tiposReceitas:any = []; 
    empresas:any = [];
    tipoAditamentos:any = [];

    headers = [
      {id:1, nome:"Contrato"},
      {id:2, nome:"Nº da Parcela"},
      {id:3, nome:"Total de Parcela"},
      {id:4, nome:"Cliente"},
      {id:5, nome:"Tipo de Receita"},
      {id:6, nome:"Empresa"},
      {id:7, nome:"Empreendimento"},
      {id:8, nome:"Quadra"},
      {id:9, nome:"Lote"},
      {id:10, nome:"Vencimento"},
      {id:11, nome:"Data de Pagamento"},
      {id:12, nome:"Valor da Parcela"},
      {id:13, nome:"Situação da Parcela"},
      {id:14, nome:"Forma de Pagamento"},
      {id:15, nome:"Baixa Automática"},
      {id:16, nome:"Encargos"},
      {id:17, nome:"Descontos"},
      {id:18, nome:"Valor Pago"},
      {id:19, nome:"Valor Total Pago Parcela"},
      {id:20, nome:"Usuário da Baixa"},
      {id:21, nome:"Tipo de Aditamento"},
      {id:22, nome:"Corretor"}
    ];

  mounted() {
    new EmpresaService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id, nome', '').then(
      res => { 
        this.empresas = res.data.items
      }
    )

    new TipoAditamentoContrato().ListarTudo().then(
      res => { this.tipoAditamentos = res.data.items
      } 
    )

    new EmpreendimentoService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id, nome', '').then(
      (res) => { 
        this.empreendimentos = res.data.items
      }
    );

    new FormaPagamentoService().ListarTudo().then(
      res => { 
      this.formaPagamento = res.data.items
      }
    )

    new UsuarioService().ListarTudo().then(
      res => { 
        this.listaUsuarios = res.data.items
      }
    )

    new PessoaService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id,nome,cnpjCpf, nomeCnpjCpf', '').then(
      res => {
        this.pessoas = res.data.items
      }
    )

    new SituacaoReceitaParcelaService().ListarTudo().then(
      res => {
        this.situacoes = res.data.items
      }
    ) 

    new TipoReceitaService().ListarTudo().then(
      res => { 
        this.tiposReceitas = res.data.items
      } 
    )
  } 

  Visualizar() {
    if((!this.filter.dataVencimentoInicial || !this.filter.dataVencimentoFinal) && (!this.filter.dataBaixaInicial || !this.filter.dataBaixaFinal)){
        this.$swal("Aviso", "Obrigatório preencher início e fim de pelo menos uma das datas.", "warning");
        return true;
    }    
    let routeLink = this.$router.resolve({
      name: "relPriceSac", 
      query: { 
        empreendimentoId: this.filter.empreendimentoId ? this.filter.empreendimentoId.toString() : "",
        numeroContrato:this.filter.numeroContrato ? this.filter.numeroContrato : "",
        formaPagamento: this.filter.formaPagamento ? this.filter.formaPagamento : "",
        usuarioBaixa: this.filter.usuarioBaixa ? this.filter.usuarioBaixa : "",
        clienteId: this.filter.clienteId ? this.filter.clienteId : "",
        situacaoParcelaId: this.filter.situacaoParcelaId ? this.filter.situacaoParcelaId.toString() : "",
        contratoId:this.filter.contratoId ? this.filter.contratoId : "",
        tipoReceitaId: this.filter.tipoReceitaId ? this.filter.tipoReceitaId.toString() : "",
        dataVencimentoInicial:this.filter.dataVencimentoInicial ? this.filter.dataVencimentoInicial: "",
        dataVencimentoFinal: this.filter.dataVencimentoFinal ? this.filter.dataVencimentoFinal: "",
        dataBaixaInicial:this.filter.dataBaixaInicial ? this.filter.dataBaixaInicial: "",
        dataBaixaFinal:this.filter.dataBaixaFinal ? this.filter.dataBaixaFinal: "",
        empresaId: this.filter.empresaId ? this.filter.empresaId.toString() : "",
      },
    });
    window.open(routeLink.href, "_blank");
  }
}
